import { useState, useEffect } from 'react';

interface IUseIntervalCountdown {
  duration: number;
}

export const useIntervalCountdown = ({
  duration,
}: IUseIntervalCountdown): boolean => {
  const [isCounting, setIsCounting] = useState<boolean>(true);
  const finishedCounting = () => {
    setIsCounting(false);
  };

  useEffect(() => {
    if (duration <= 0) {
      return finishedCounting();
    }

    const timer = setTimeout(() => {
      finishedCounting();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  return isCounting;
};
