import axios from 'axios';
import { IHeyLightCreditFinanceTerm } from '@api/HeyLight';
import { Nullable } from '@appTypes';

interface IGetCreditFianceTermsApi {
  allowedTerms: Nullable<string>;
  amount: Nullable<string>;
  currency: Nullable<string>;
  publicApiKey: Nullable<string>;
  sku: Nullable<string>;
}

export const getCreditFinanceTermsApi = async ({
  allowedTerms,
  amount,
  currency,
  publicApiKey,
  sku,
}: IGetCreditFianceTermsApi) => {
  try {
    const response = await axios.get<IHeyLightCreditFinanceTerm[]>(
      // eslint-disable-next-line max-len
      `/credit-finance-terms?currency=${currency}&amount=${amount}&sku=${sku}&publicApiKey=${publicApiKey}&allowedTerms=${allowedTerms}`,
    );
    return response.data;
  } catch (error) {
    return [];
  }
};
