import { Notes } from '@heidi-pay/heidi-component-library/components';
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { AppCopyBlock } from '@components/CopyBlock';
import { getHeyLightTextBlockKey } from '@utils/i18next/utils';

export const MessageCRIF = () => (
  <Notes color={BaseColour.Grey1}>
    <AppCopyBlock
      i18nKey={getHeyLightTextBlockKey('weCheckWithCRIF.content')}
    />
  </Notes>
);
