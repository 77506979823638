import {
  Body,
  ProgressBar,
} from '@heidi-pay/heidi-component-library/components';
import { Box } from '@mui/material';
import { TextChildren } from '@appTypes';
import { ProgressBar as ProgressBarLegacy } from '@components';

interface IPanelLoading {
  children?: TextChildren;
  isMigrated?: boolean;
}

export const PanelLoading = ({
  children,
  isMigrated = false,
}: IPanelLoading) => (
  <Box>
    {children ? <Body>{children}</Body> : null}
    {isMigrated ? (
      <ProgressBar
        isRounded={true}
        sx={{ mt: '20px' }}
        variant="indeterminate"
      />
    ) : (
      <ProgressBarLegacy />
    )}
  </Box>
);
