import { MousePointer } from '@heidi-pay/heidi-component-library/components';
import digitalRedActive from '@assets/icons/digital-red-active.svg';
import easyRedActive from '@assets/icons/easy-red-active.svg';
import rocketRedActive from '@assets/icons/rocket-red-active.svg';
import { AppCopyBlock } from '@components';
import {
  getHeyLightTextBlockKey,
  getPagolightTextBlockKey,
} from '@utils/i18next/utils';

export const featureTiles = [
  {
    content: (
      <AppCopyBlock i18nKey={getPagolightTextBlockKey('simple.content')} />
    ),
    imgAlt: 'simple icon',
    imgSrc: easyRedActive,
    title: <AppCopyBlock i18nKey={getPagolightTextBlockKey('simple.title')} />,
  },
  {
    content: (
      <AppCopyBlock i18nKey={getPagolightTextBlockKey('fast.content')} />
    ),
    imgAlt: 'fast icon',
    imgSrc: rocketRedActive,
    title: <AppCopyBlock i18nKey={getPagolightTextBlockKey('fast.title')} />,
  },
  {
    content: (
      <AppCopyBlock i18nKey={getPagolightTextBlockKey('digital.content')} />
    ),
    imgAlt: 'digital icon',
    imgSrc: digitalRedActive,
    title: <AppCopyBlock i18nKey={getPagolightTextBlockKey('digital.title')} />,
  },
];

export const featureTilesMigrated = [
  {
    content: (
      <AppCopyBlock i18nKey={getHeyLightTextBlockKey('simple.content')} />
    ),
    iconTitleKey: getHeyLightTextBlockKey('simple.title'),
    Icon: MousePointer,
    title: <AppCopyBlock i18nKey={getHeyLightTextBlockKey('simple.title')} />,
  },
];
