import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  PanelTitle,
  Panel,
} from '@heidi-pay/heidi-component-library/components';
import {
  IPricingOfferApiResponse,
  IPricingOffer,
  getPagolightProPricingOffersApi,
} from '@api';
import { AppCopyBlock, PanelLoading } from '@components';
import { closeUpstreamModal } from '@utils';
import { getHeyLightTextBlockKey } from '@utils/i18next/utils';
import { PricingOffersContent } from './PricingOfferContent';

export const HeyLightFinancingIT = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const key = queryParams.get('key');
  const code = queryParams.get('code');
  const amount = queryParams.get('amount');
  const terms = queryParams.get('terms');

  const [pricingPlans, setPricingPlans] = useState<IPricingOfferApiResponse>();
  const [pricingOffers, setPricingOffers] = useState<IPricingOffer[]>([]);

  useEffect(() => {
    if (pricingPlans) {
      const offerObject = pricingPlans.pricingOffers ?? {};
      const offers = Object.entries(offerObject).map(entry => entry[1]);
      const sortedOffers = [...offers].sort(
        (a, b) => b.response_term - a.response_term,
      );
      setPricingOffers(sortedOffers);
    }
  }, [pricingPlans]);

  useEffect(() => {
    if (pricingOffers.length) {
      setIsLoading(false);
    }
  }, [pricingOffers]);

  const getPricingOffers = useCallback(async () => {
    if (amount && code && key && terms) {
      const pricingOfferContent = await getPagolightProPricingOffersApi(
        amount,
        code,
        key,
        terms,
      );
      setPricingPlans(pricingOfferContent);
    }
  }, [amount, code, key, terms]);

  useEffect(() => {
    getPricingOffers();
  }, [getPricingOffers]);

  const titleKey = isLoading ? 'momentOfPatience' : 'chooseYourInstallment';

  return (
    <Panel
      isDialog={true}
      maxContentHeight="100vh"
      minContentHeight="150px"
      onClose={closeUpstreamModal}
    >
      <PanelTitle
        subTitle={
          isLoading ? (
            <AppCopyBlock
              i18nKey={getHeyLightTextBlockKey('loadingConditions.content')}
            />
          ) : undefined
        }
        title={
          <AppCopyBlock
            i18nKey={getHeyLightTextBlockKey(`${titleKey}.title`)}
          />
        }
      />
      {isLoading ? (
        <PanelLoading isMigrated={true} />
      ) : (
        <PricingOffersContent
          isMigrated={true}
          pricingOfferContentOptions={{ hasBackgroundOverlay: true }}
          pricingOffers={pricingOffers}
          pricingOffersSx={{ marginTop: '20px' }}
        />
      )}
    </Panel>
  );
};
