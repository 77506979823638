import { useTheme } from '@mui/material';

interface IHeyLightLink {
  color?: string;
  fontWeight?: string;
  textDecoration?: string;
}

export const HeyLightLink = ({
  color,
  fontWeight = 'normal',
  textDecoration = 'unset',
}: IHeyLightLink) => {
  const theme = useTheme();
  const { main } = theme.palette.primary;

  return (
    <a
      href="https://www.heylight.com/"
      rel="noreferrer"
      style={{ color: color ?? main, fontWeight, textDecoration }}
      target="_blank"
    >
      heylight.com
    </a>
  );
};
