import { Routes, Route } from 'react-router-dom';
import {
  Error404,
  HeyLightBnplCh,
  HeyLightBnplChWithInstalments,
  HeyLightBnplIT,
  HeyLightFinancingCh,
  HeyLightFinancingIT,
  HeyLightSplashScreen,
  PagolightPromotion,
  PagolightProPricingOffers,
} from '@pages';
import { routePath } from '@utils/constants';
import { ApplicationRoutes } from '@utils/enums';
import { Layout } from './Layout';

const heyLightRouteMap = {
  [ApplicationRoutes.HeyLightBnplCh]: HeyLightBnplCh,
  [ApplicationRoutes.HeyLightBnplChCard]: () => (
    <HeyLightBnplChWithInstalments isInterestFree={true} />
  ),
  [ApplicationRoutes.HeyLightBnplChWithCharge]: () => (
    <HeyLightBnplChWithInstalments isInterestFree={false} />
  ),
  [ApplicationRoutes.HeyLightBnplIt]: HeyLightBnplIT,
  [ApplicationRoutes.HeyLightFinancingCh]: HeyLightFinancingCh,
  [ApplicationRoutes.HeyLightFinancingIt]: HeyLightFinancingIT,
  [ApplicationRoutes.PagolightPromotion]: PagolightPromotion,
  [ApplicationRoutes.PagolightProPricingOffers]: PagolightProPricingOffers,
  [ApplicationRoutes.HeyLightSplashScreen]: HeyLightSplashScreen,
};

export const AppRoutes = () => (
  <Routes>
    <Route element={<Layout />} path={routePath}>
      {Object.entries(heyLightRouteMap).map(([route, Component]) => (
        <Route element={<Component />} key={route} path={route} />
      ))}
      <Route element={<Error404 />} path="*" />
    </Route>
  </Routes>
);
