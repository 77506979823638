import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButtonList } from '@heidi-pay/heidi-component-library/components';
import { SxProps } from '@mui/system';
import { IPricingOffer } from '@api';
import { PricingTable, ExpandableTermsPanelContent } from '@components';
import { getPagolightTextBlockKey } from '@utils/i18next/utils';
import { getPagolightProPricingOfferContent } from './utils/getPagolightProPricingOfferContent';

export interface IPricingOffersOptions {
  hasBackgroundOverlay: boolean;
}

interface IPricingOffersContent {
  isMigrated?: boolean;
  pricingOfferContentOptions?: IPricingOffersOptions;
  pricingOffers: IPricingOffer[];
  pricingOffersSx?: SxProps;
}

export const PricingOffersContent = ({
  isMigrated,
  pricingOfferContentOptions = { hasBackgroundOverlay: false },
  pricingOffers,
  pricingOffersSx = {},
}: IPricingOffersContent) => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState<number>(0);
  const [activeOffer, setActiveOffer] = useState<IPricingOffer>();

  useEffect(() => {
    if (pricingOffers.length) {
      setActiveKey(pricingOffers[0].response_term);
    }
  }, [pricingOffers]);

  useEffect(() => {
    if (activeKey) {
      setActiveOffer(pricingOffers.find(x => x.response_term === activeKey));
    }
  }, [activeKey, pricingOffers]);

  const transform = useCallback(
    ({ pay_monthly: payMonthly, response_term: responseTerm }: IPricingOffer) =>
      t(getPagolightTextBlockKey('paymentTermAndPrice.content'), {
        payMonthly,
        responseTerm,
      }),
    [t],
  );

  const options = pricingOffers.map(offer => ({
    key: offer.response_term,
    label: transform(offer),
  }));

  const { pricingOfferSmallPrint, pricingTableRows, pricingTableRowsMigrated } =
    getPagolightProPricingOfferContent({ activeOffer, t, isMigrated });

  return (
    <ExpandableTermsPanelContent
      {...pricingOfferSmallPrint}
      hasBackgroundOverlay={pricingOfferContentOptions.hasBackgroundOverlay}
      isMigrated={isMigrated}
    >
      <ToggleButtonList
        onClick={setActiveKey}
        options={options}
        sx={pricingOffersSx}
        toggleButtonListName="pricing-offer-picker"
        value={activeKey}
      />
      <PricingTable
        isMigrated={isMigrated}
        pricingValues={pricingTableRows}
        pricingValuesMigrated={pricingTableRowsMigrated}
      />
    </ExpandableTermsPanelContent>
  );
};
