import {
  Bag,
  MousePointer,
  ThumbsUpCalculator,
} from '@heidi-pay/heidi-component-library/components';
import { AppCopyBlock } from '@components';
import { getHeyLightTextBlockKey } from '@utils/i18next/utils';

export const featureTilesMigrated = [
  {
    Icon: Bag,
    title: (
      <AppCopyBlock
        i18nKey={getHeyLightTextBlockKey('addYourFavouriteItems.title')}
      />
    ),
  },
  {
    Icon: MousePointer,
    title: (
      <AppCopyBlock
        i18nKey={getHeyLightTextBlockKey('selectHeyLightAtCheckout.title')}
      />
    ),
  },
  {
    Icon: ThumbsUpCalculator,
    title: (
      <AppCopyBlock
        i18nKey={getHeyLightTextBlockKey('payInInterestFreeInstalments.title')}
      />
    ),
  },
];
