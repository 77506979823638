import { ThemeProvider, createTheme } from '@mui/material';
import { Children } from '@appTypes';
import { useGetTheme } from '@hooks';

interface IThemeWrapper {
  children: Children;
}

export const ThemeWrapper = ({ children }: IThemeWrapper) => {
  const theme = useGetTheme();
  return <ThemeProvider theme={createTheme(theme)}>{children}</ThemeProvider>;
};
