interface IGetLanguage {
  lang: string;
}

export const getSupportedLanguage = ({ lang }: IGetLanguage) => {
  const acceptedLangPrefixes = ['de', 'en', 'fr', 'it'];
  if (lang.length >= 2) {
    const langPrefix = lang.toLowerCase().substring(0, 2);
    if (acceptedLangPrefixes.includes(langPrefix)) {
      return lang.toLowerCase();
    }
  }
  return 'en';
};
