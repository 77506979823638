import { Title, Subtitle } from '@heidi-pay/heidi-component-library/components';
import { Box, styled } from '@mui/material';
import { Size } from '@styles';

const PanelTitleStyled = styled(Box)({
  paddingLeft: Size.Md,
  '& h1, & h2': {
    margin: '0px',
  },
});

interface IPanelTitle {
  subTitle?: string | JSX.Element;
  title?: string | JSX.Element;
}

export const PanelTitle = ({ subTitle, title }: IPanelTitle) => (
  <PanelTitleStyled>
    <Title>{title}</Title>
    {subTitle ? (
      <Box sx={{ mt: Size.Md }}>
        <Subtitle>{subTitle}</Subtitle>
      </Box>
    ) : null}
  </PanelTitleStyled>
);
