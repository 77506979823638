import { Body } from '@heidi-pay/heidi-component-library/components';
import { TableCell, TableRow, styled } from '@mui/material';
import { Colour } from '@styles';

const TableCellStyled = styled(TableCell)({
  borderBottom: 'none',
  padding: '0px',
});

export interface IPricingTableRow {
  hasBorderTop?: boolean;
  label?: string;
  value: string;
}

export const PricingTableRow = ({
  hasBorderTop,
  label,
  value,
}: IPricingTableRow) => {
  return (
    <TableRow
      sx={{ borderTop: hasBorderTop ? `1px solid ${Colour.MidGrey}` : '' }}
    >
      {label ? (
        <TableCellStyled>
          <Body component="div">{label}</Body>
        </TableCellStyled>
      ) : null}
      <TableCellStyled colSpan={label ? 1 : 2}>
        <Body component="div" fontWeight="bold" textAlign="right">
          {value}
        </Body>
      </TableCellStyled>
    </TableRow>
  );
};
