import axios from 'axios';
import { Nullable } from '@appTypes';
import { IPricingOfferApiResponse } from './types';

export const getPagolightProPricingOffersApi = async (
  amount: Nullable<string>,
  code: Nullable<string>,
  key: Nullable<string>,
  terms: Nullable<string>,
) => {
  try {
    const response = await axios.get<IPricingOfferApiResponse>(
      `/pricing-offers?amount=${amount}&code=${code}&key=${key}&terms=${terms}`,
    );
    return response.data;
  } catch (error) {
    return {};
  }
};
