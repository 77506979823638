import { AriaRoles } from '@heidi-pay/heidi-common-fe/enums';
import { styled } from '@mui/material';
import { alpha, keyframes } from '@mui/system';
import { Size } from '@styles';

const scrollGradient = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -200% 0%;
  }
`;

export const ProgressBarStyled = styled('div')(({ theme }) => ({
  animation: `${scrollGradient} 2s linear infinite`,
  background: `repeating-linear-gradient(
    to right, 
    ${theme.palette.primary.dark} 0%,
    ${theme.palette.primary.main} 80%, 
    ${alpha(theme.palette.primary.main, 0.75)} 98%,
    ${theme.palette.primary.dark} 100%
  )`,
  backgroundSize: '200% 200%',
  borderRadius: Size.Xxs,
  height: Size.Xs,
  margin: `${Size.Md} 0px`,
  width: '100%',
}));

export const ProgressBar = () => (
  <ProgressBarStyled role={AriaRoles.ProgressBar} />
);
