import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { routeConfig } from '@utils/constants';
import { Language } from '@utils/enums';
import i18n from '../index';

export const getRouteSupportedLanguage = (pathname: string, lang: Language) => {
  const config = routeConfig[pathname];

  // if there is a route config, then use it
  if (config) {
    const isSupportedRouteLanguage = config.supportedLanguages.includes(lang);

    // if the route supports language provided then use it, otherwise use route default language
    return isSupportedRouteLanguage ? lang : config.defaultLanguage;
  }

  // if no route config assume the route allows any language
  return lang;
};

export const useSetLanguage = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const lang = (queryParams.get('lang') as Language) ?? Language.En;

  useEffect(() => {
    const selectedLanguage = getRouteSupportedLanguage(location.pathname, lang);
    i18n.changeLanguage(selectedLanguage);
  }, [lang, location.pathname]);
};
