import { heyLightTheme } from '@heidi-pay/heidi-component-library/themes';
import { pagolightProTheme, pagolightTheme } from '@styles/themes';
import { ApplicationRoutes, Language } from './enums';
import { IRouteConfig } from './types';

export const routePath = '/app';

export const supportedCurrencyMapping = {
  '€': 'EUR',
  EUR: 'EUR',
  '£': 'GBP',
  GBP: 'GBP',
  CHF: 'CHF',
};

const compassLanguageDefaults = {
  supportedLanguages: [Language.It],
  defaultLanguage: Language.It,
};

export const routeConfig: Record<string, IRouteConfig> = {
  [`${routePath}/${ApplicationRoutes.PagolightPromotion}`]: {
    ...compassLanguageDefaults,
    theme: pagolightTheme,
  },
  [`${routePath}/${ApplicationRoutes.PagolightProPricingOffers}`]: {
    ...compassLanguageDefaults,
    theme: pagolightProTheme,
  },
  [`${routePath}/${ApplicationRoutes.HeyLightBnplIt}`]: {
    ...compassLanguageDefaults,
    theme: heyLightTheme,
  },
  [`${routePath}/${ApplicationRoutes.HeyLightFinancingIt}`]: {
    ...compassLanguageDefaults,
    theme: heyLightTheme,
  },
};
