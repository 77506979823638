/* eslint-disable max-len */
import { useTheme } from '@mui/material';

export const InfoIcon = () => {
  const theme = useTheme();
  const { main } = theme.palette.primary;

  return (
    <svg
      height="16px"
      version="1.1"
      viewBox="0 0 16 16"
      width="16px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Info icon</title>
      <g
        fill="none"
        fillRule="evenodd"
        id="ions/icon-xs/info-red"
        stroke="none"
        strokeWidth="1"
      >
        <path
          d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z"
          fill={main}
          fillRule="nonzero"
          id="Oval"
        ></path>
        <circle cx="8" cy="5" fill={main} id="Oval" r="1"></circle>
        <rect
          fill={main}
          height="5"
          id="Rectangle"
          rx="1"
          width="2"
          x="7"
          y="7"
        ></rect>
      </g>
    </svg>
  );
};
