import {
  Body,
  ChevronDown,
  H6,
  Notes,
} from '@heidi-pay/heidi-component-library/components';
import { Box, IconButton, styled } from '@mui/material';
import { CloseButton } from '@components';
import { Colour, Size, Font } from '@styles';

interface IStyledMigrated {
  isMigrated?: boolean;
}

const OpenNotes = styled(Notes)<IStyledMigrated>(({ isMigrated, theme }) => ({
  fontStyle: isMigrated ? 'normal' : 'italic',
  fontFamily: isMigrated ? theme.typography.fontFamily : Font.SourceSans,
}));

const OpenContent = styled(Box)<IStyledMigrated>(({ isMigrated, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: isMigrated ? theme.panel.space.xxs : Size.Md,
}));

const OpenContentHeader = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});

interface IExpandableTermsPanelContent {
  isMigrated?: boolean;
  openContent?: string | JSX.Element;
  openTitle?: string | JSX.Element;
  toggleOpen: () => void;
}

export const ExpandableTermsPanelOpenContent = ({
  isMigrated = false,
  openContent,
  openTitle,
  toggleOpen,
}: IExpandableTermsPanelContent) => {
  return (
    <OpenContent isMigrated={isMigrated}>
      <OpenContentHeader>
        {isMigrated ? (
          <>
            <H6 component="p">{openTitle}</H6>
            <IconButton onClick={toggleOpen} sx={{ mr: `-${Size.Xs}` }}>
              <ChevronDown />
            </IconButton>
          </>
        ) : (
          <>
            <Body fontWeight="bold">{openTitle}</Body>
            <CloseButton
              colour={Colour.TxtGrey}
              onClick={toggleOpen}
              sx={{ mr: `-${Size.Xs}` }}
            />
          </>
        )}
      </OpenContentHeader>
      <OpenNotes component="div" isMigrated={isMigrated}>
        {openContent}
      </OpenNotes>
    </OpenContent>
  );
};
