import { useTranslation } from 'react-i18next';
import {
  Notes,
  Panel,
  PanelTitle,
} from '@heidi-pay/heidi-component-library/components';
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { Box, styled } from '@mui/material';

import { AppCopyBlock, FeatureTileMigrated } from '@components';
import { closeUpstreamModal } from '@utils';
import { getHeyLightTextBlockKey } from '@utils/i18next/utils';
import { featureTilesMigrated } from './constants';

const PromotionalMessageContentBase = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const PromotionalMessageContent = styled(PromotionalMessageContentBase)(
  ({ theme }) => ({
    gap: theme.panel.space.xs,
  }),
);

export const HeyLightBnplIT = () => {
  const { t } = useTranslation();

  return (
    <Panel
      isDialog={true}
      maxContentHeight="100vh"
      minContentHeight="unset"
      onClose={closeUpstreamModal}
    >
      <PromotionalMessageContent>
        <PanelTitle
          subTitle={
            <AppCopyBlock
              i18nKey={getHeyLightTextBlockKey('buyNowPayLater.subtitle')}
            />
          }
          title={
            <AppCopyBlock
              i18nKey={getHeyLightTextBlockKey('buyNowPayLater.title')}
            />
          }
        />
        <PromotionalMessageContentBase>
          {featureTilesMigrated.map(props => (
            <FeatureTileMigrated
              {...props}
              iconTitle={t(props.iconTitleKey)}
              key={props.iconTitleKey}
            />
          ))}
        </PromotionalMessageContentBase>
        <Notes color={BaseColour.Grey1}>
          <AppCopyBlock
            i18nKey={getHeyLightTextBlockKey('pagolightTerms.content')}
          />
        </Notes>
      </PromotionalMessageContent>
    </Panel>
  );
};
