import { Notes } from '@heidi-pay/heidi-component-library/components';
import { Grid } from '@mui/material';
import {
  ActionButtonContainer,
  AppCopyBlock,
  PanelDialog,
  FeatureTile,
  ActionButton,
} from '@components';
import { closeUpstreamModal } from '@utils';
import { getPagolightTextBlockKey } from '@utils/i18next/utils';
import { featureTiles } from './constants';

export const PagolightPromotion = () => (
  <PanelDialog
    minContentHeight="unset"
    onClose={closeUpstreamModal}
    subtitle={
      <AppCopyBlock
        i18nKey={getPagolightTextBlockKey('buyNowPayLater.subtitle')}
      />
    }
    title={
      <AppCopyBlock
        i18nKey={getPagolightTextBlockKey('buyNowPayLater.title')}
      />
    }
  >
    <Grid container={true}>
      {featureTiles.map(props => (
        <FeatureTile {...props} key={props.imgAlt} />
      ))}
    </Grid>
    <ActionButtonContainer>
      <ActionButton onClick={closeUpstreamModal}>
        <AppCopyBlock i18nKey={getPagolightTextBlockKey('close.label')} />
      </ActionButton>
    </ActionButtonContainer>
    <Notes component="div">
      <AppCopyBlock
        i18nKey={getPagolightTextBlockKey('pagolightTerms.content')}
      />
    </Notes>
  </PanelDialog>
);
