import { heyLightTypographyComponents } from '@heidi-pay/heidi-component-library/components';
import { TranslateInterpolation } from '@appTypes';
import {
  CompassLink,
  HeyLightLink,
  MailToLink,
  PagolightLink,
} from '@components';
import { CopyBlock } from './CopyBlock';

interface IAppCopyBlock {
  i18nKey: string;
  values?: TranslateInterpolation;
}

export const AppCopyBlock = ({ i18nKey, values }: IAppCopyBlock) => (
  <CopyBlock
    extraComponents={{
      compassLink: <CompassLink />,
      crifLink: <MailToLink email="support.ch@crif.com" />,
      pagolightLink: <PagolightLink />,
      heylightLink: <HeyLightLink />,
      ...heyLightTypographyComponents,
    }}
    i18nKey={i18nKey}
    values={values}
  />
);
