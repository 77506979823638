import { useLocation } from 'react-router-dom';
import { heyLightTheme } from '@heidi-pay/heidi-component-library/themes';
import { routeConfig } from '@utils/constants';
import { ThemeOverride } from '@utils/enums';

export const useIsMigrated = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const theme = queryParams.get('themeOverride') ?? ThemeOverride.Legacy;

  return theme === ThemeOverride.HeyLight;
};

export const useGetTheme = () => {
  const location = useLocation();
  const isMigrated = useIsMigrated();

  if (isMigrated) {
    return heyLightTheme;
  }

  return routeConfig[location.pathname]?.theme ?? heyLightTheme;
};
