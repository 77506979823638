import { Link, styled } from '@mui/material';

export const LinkButton = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '2px 0',
  '&:hover': {
    cursor: 'pointer',
  },
  '& > * ': {
    color: `${theme.palette.primary.main}!important`,
    textDecoration: `underline ${theme.palette.primary.main}!important`,
  },
}));
