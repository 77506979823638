import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { de, en, fr, it } from './translations';

const resources = {
  de: { translation: de },
  en: { translation: en },
  fr: { translation: fr },
  it: { translation: it },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
  returnObjects: true,
});

export default i18n;
