import { styled, useTheme } from '@mui/material';

interface ILogoStyled {
  width: string;
}

const LogoStyled = styled('img')(({ width }: ILogoStyled) => ({
  width,
}));

export const Logo = () => {
  const theme = useTheme();
  const { altText, src, width } = theme.images.logo;

  return <LogoStyled alt={altText} src={src} width={width} />;
};
