interface ICompassLink {
  textDecoration?: string;
}

export const CompassLink = ({ textDecoration = 'underline' }: ICompassLink) => (
  <a
    href="https://www.compass.it/"
    rel="noreferrer"
    style={{ color: 'inherit', textDecoration }}
    target="_blank"
  >
    compass.it
  </a>
);
