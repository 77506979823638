import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  PanelTitle,
  Panel,
} from '@heidi-pay/heidi-component-library/components';
import {
  IHeyLightCreditFinanceTerm,
  getCreditFinanceTermsApi,
} from '@api/HeyLight';
import { AppCopyBlock, PanelLoading } from '@components';
import { closeUpstreamModal } from '@utils';
import { getHeyLightTextBlockKey } from '@utils/i18next/utils';
import { PricingOffersContent } from './PricingOfferContent';

export const HeyLightFinancingCh = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const publicApiKey = queryParams.get('publicApiKey');
  const sku = queryParams.get('sku');
  const amount = queryParams.get('amount');
  const allowedTerms = queryParams.get('allowedTerms');
  const currency = queryParams.get('currency');

  const [pricingOffers, setPricingOffers] = useState<
    IHeyLightCreditFinanceTerm[]
  >([]);

  useEffect(() => {
    if (pricingOffers.length && currency) {
      setIsLoading(false);
    }
  }, [currency, pricingOffers]);

  const getPricingOffers = useCallback(async () => {
    if (amount && publicApiKey && allowedTerms && currency) {
      const pricingOfferContent = await getCreditFinanceTermsApi({
        amount,
        currency,
        publicApiKey,
        sku,
        allowedTerms,
      });
      setPricingOffers(pricingOfferContent);
    }
  }, [amount, publicApiKey, allowedTerms, currency, sku]);

  useEffect(() => {
    getPricingOffers();
  }, [getPricingOffers]);

  const titleKey = isLoading ? 'momentOfPatience' : 'financing';

  return (
    <Panel
      isDialog={true}
      maxContentHeight="100vh"
      minContentHeight="150px"
      onClose={closeUpstreamModal}
    >
      <PanelTitle
        subTitle={
          isLoading ? (
            <AppCopyBlock
              i18nKey={getHeyLightTextBlockKey('loadingConditions.content')}
            />
          ) : undefined
        }
        title={
          <AppCopyBlock
            i18nKey={getHeyLightTextBlockKey(`${titleKey}.title`)}
          />
        }
      />
      {isLoading ? (
        <PanelLoading isMigrated={true} />
      ) : (
        <PricingOffersContent
          currency={String(currency)}
          pricingOffers={pricingOffers}
        />
      )}
    </Panel>
  );
};
