import { Body } from '@heidi-pay/heidi-component-library/components';
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { Box, styled } from '@mui/material';
import { AppCopyBlock } from '@components';
import { getHeyLightTextBlockKey } from '@utils/i18next/utils';

const ContentBase = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.panel.space.xxs,
}));

const UList = styled('ul')({
  paddingInline: '24px',
  margin: 0,
  color: BaseColour.Grey1,
});

const requirements = [
  {
    content: (
      <AppCopyBlock
        i18nKey={getHeyLightTextBlockKey('beBetween18And70YearsOld.content')}
      />
    ),
    key: 'beBetween18And70YearsOld',
  },
  {
    content: (
      <AppCopyBlock
        i18nKey={getHeyLightTextBlockKey('beResidentInSwitzerland.content')}
      />
    ),
    key: 'beResidentInSwitzerland',
  },
  {
    content: (
      <AppCopyBlock
        i18nKey={getHeyLightTextBlockKey(
          'beSwissCitizenOrHaveResidencePermitCOrB.content',
        )}
      />
    ),
    key: 'beSwissCitizenOrHaveResidencePermitCOrB',
  },
];

export const RequirementsList = () => (
  <ContentBase>
    <Body component="h2">
      <AppCopyBlock i18nKey={getHeyLightTextBlockKey('requirements.title')} />
    </Body>
    <UList>
      {requirements.map(requirement => (
        <li key={requirement.key}>
          <Body>{requirement.content}</Body>
        </li>
      ))}
    </UList>
  </ContentBase>
);
