import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import { ThemeWrapper } from '@styles';
import '@utils/i18next';
import '@styles/index.css';
import '@heidi-pay/heidi-component-library/styles/index.css';
import '@heidi-pay/heidi-component-library/themes/types';

import { AppRoutes } from './AppRoutes';

interface IApp {
  initialEntries?: string[];
  useMemoryRouter?: boolean;
}

export const App = ({ initialEntries = [], useMemoryRouter = false }: IApp) => {
  const RouterComponent = useMemoryRouter ? MemoryRouter : BrowserRouter;
  return (
    <RouterComponent initialEntries={initialEntries}>
      <ThemeWrapper>
        <AppRoutes />
      </ThemeWrapper>
    </RouterComponent>
  );
};
