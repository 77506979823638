import { baseTheme } from '@heidi-pay/heidi-component-library/themes';
import { ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { Originators } from '@utils/enums';
import { compassTheme } from './compassTheme';
import PagolightProLogo from '../../assets/logos/PagolightProLogo.svg';

const customTheme: ThemeOptions = {
  ...compassTheme,
  images: {
    logo: {
      src: PagolightProLogo,
      width: '154px',
      altText: Originators.PagoLightPro,
    },
  },
};

export const pagolightProTheme: ThemeOptions = deepmerge(
  baseTheme,
  customTheme,
);
