import { IconButton, SxProps } from '@mui/material';
import { CloseIcon } from '@components';

interface ICloseButton {
  colour?: string;
  onClick: () => void;
  sx?: SxProps;
}

export const CloseButton = ({ colour, onClick, sx = {} }: ICloseButton) => (
  <IconButton aria-label="close" onClick={onClick} sx={{ ...sx }}>
    <CloseIcon colour={colour} />
  </IconButton>
);
