import {
  Body,
  H6,
  IIconProps,
} from '@heidi-pay/heidi-component-library/components';
import { Box, styled } from '@mui/material';
import { Size } from '@styles';

interface IFeatureTimeMigratedStyled {
  isCompact: boolean;
}

const FeatureTileMigratedStyled = styled(Box, {
  shouldForwardProp: prop => prop !== 'isCompact',
})<IFeatureTimeMigratedStyled>(({ isCompact, theme }) => ({
  margin: `${isCompact ? theme.panel.space.xxs : theme.panel.space.xs} 0`,
  display: 'flex',
}));

const FeatureTileMigratedLogo = styled(Box)(({ theme }) => ({
  paddingRight: theme.panel.space.xs,
}));

const FeatureTileMigratedText = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.panel.space.xxs,
}));

interface IFeatureTileMigrated {
  Icon: (props: IIconProps) => JSX.Element;
  content?: string | JSX.Element;
  iconTitle?: string;
  isCompact?: boolean;
  title?: string | JSX.Element;
}

export const FeatureTileMigrated = ({
  content: subtitle,
  Icon,
  iconTitle,
  isCompact = false,
  title,
}: IFeatureTileMigrated) => (
  <FeatureTileMigratedStyled isCompact={isCompact}>
    <FeatureTileMigratedLogo>
      <Icon title={iconTitle} />
    </FeatureTileMigratedLogo>
    <FeatureTileMigratedText>
      {title ? (
        <H6 component="p" fontWeight={subtitle ? 'bold' : '400'}>
          {title}
        </H6>
      ) : null}
      {subtitle ? <Body lineHeight={Size.Lg}>{subtitle}</Body> : null}
    </FeatureTileMigratedText>
  </FeatureTileMigratedStyled>
);
