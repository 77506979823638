import { useCallback, useState } from 'react';
import { Box, styled } from '@mui/material';
import { Children } from '@appTypes';
import { ExpandablePanelFooter } from '@components';
import { ExpandableTermsPanelClosedContent } from './ExpandablePanelClosedContent';
import { ExpandableTermsPanelOpenContent } from './ExpandableTermsPanelOpenContent';

interface IStyledMigrated {
  isMigrated?: boolean;
}

const ExpandableTermsPanelContentStyled = styled(Box)<IStyledMigrated>(
  ({ isMigrated, theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: isMigrated ? theme.panel.space.xs : undefined,
  }),
);

const ExpandableBody = styled(Box)<IStyledMigrated>(
  ({ isMigrated, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '300px',
    gap: isMigrated ? theme.panel.space.xxs : undefined,
  }),
);

const OverlayBackground = styled(Box)({
  position: 'absolute',
  left: 0,
  top: '-66px',
  background: 'white',
  width: '100%',
  height: 'calc(100% + 66px)',
  opacity: 0.75,
  zIndex: 1,
});

interface IExpandableTermsPanelContent {
  children: Children;
  closedContent?: string | JSX.Element;
  closedLinkText?: string | JSX.Element;
  hasBackgroundOverlay: boolean;
  isMigrated?: boolean;
  openContent?: string | JSX.Element;
  openTitle?: string | JSX.Element;
}

export const ExpandableTermsPanelContent = ({
  children,
  closedContent,
  closedLinkText,
  hasBackgroundOverlay,
  isMigrated = false,
  openContent,
  openTitle,
}: IExpandableTermsPanelContent) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  return (
    <>
      {hasBackgroundOverlay && open ? (
        <OverlayBackground className="overlay-background" />
      ) : null}
      <ExpandableTermsPanelContentStyled isMigrated={isMigrated}>
        <ExpandableBody isMigrated={isMigrated}>{children}</ExpandableBody>
        {closedContent && closedLinkText && openContent && openTitle ? (
          <ExpandablePanelFooter
            closedContent={
              <ExpandableTermsPanelClosedContent
                closedContent={closedContent}
                closedLinkText={closedLinkText}
                isMigrated={isMigrated}
                toggleOpen={toggleOpen}
              />
            }
            isMigrated={isMigrated}
            open={open}
            openContent={
              <ExpandableTermsPanelOpenContent
                isMigrated={isMigrated}
                openContent={openContent}
                openTitle={openTitle}
                toggleOpen={toggleOpen}
              />
            }
          />
        ) : null}
      </ExpandableTermsPanelContentStyled>
    </>
  );
};
