import { baseTheme } from '@heidi-pay/heidi-component-library/themes';
import { ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { Originators } from '@utils/enums';
import { compassTheme } from './compassTheme';
import PagolightLogo from '../../assets/logos/PagolightLogo.svg';

const customTheme: ThemeOptions = {
  ...compassTheme,
  images: {
    logo: {
      src: PagolightLogo,
      width: '132px',
      altText: Originators.PagoLight,
    },
  },
};

export const pagolightTheme: ThemeOptions = deepmerge(baseTheme, customTheme);
