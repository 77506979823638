import { AriaRoles } from '@heidi-pay/heidi-common-fe/enums';
import { Box, styled } from '@mui/material';
import { Children } from '@appTypes';
import { Size } from '@styles';

const PanelStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.panel.background,
  borderRadius: Size.Md,
  maxHeight: '100vh',
  overflow: 'auto',
}));

interface IPanel {
  children: Children;
  width?: string;
}

export const Panel = ({ children, width }: IPanel) => (
  <PanelStyled role={AriaRoles.Dialog} width={width}>
    {children}
  </PanelStyled>
);
