import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SplashScreenAlt } from '@heidi-pay/heidi-component-library/components';
import { styled } from '@mui/material';
import { useIntervalCountdown } from '@hooks';
import { getSupportedLanguage } from '@utils/i18next/utils';

export const StyledSplashScreenContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  zindex: 10000;
`;

interface IHeyLightSplashScreen {
  interval?: number;
  redirectUrl?: string;
}

export const HeyLightSplashScreen = ({
  interval = 4000,
  redirectUrl = 'https://docs.heylight.com',
}: IHeyLightSplashScreen) => {
  const [redirectUrlQueryParam, setRedirectUrlQueryParam] =
    useState(redirectUrl);
  const isCounting = useIntervalCountdown({ duration: interval });
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const queryRedirectUrl = params.get('redirect_url');
    if (queryRedirectUrl) {
      setRedirectUrlQueryParam(queryRedirectUrl);
    }
  }, []);

  useEffect(() => {
    if (!isCounting) {
      window.location.assign(redirectUrlQueryParam);
    }
  }, [isCounting, redirectUrlQueryParam]);

  useEffect(() => {
    i18n.changeLanguage(getSupportedLanguage({ lang: navigator.language }));
  }, [i18n]);

  return (
    <StyledSplashScreenContainer>
      <SplashScreenAlt
        overrideLogoText={t('heyLight.textBlock.splashScreenSwiss.label')}
      />
    </StyledSplashScreenContainer>
  );
};
