import { Body, H3 } from '@heidi-pay/heidi-component-library/components';
import { Grid, styled } from '@mui/material';
import { Align, Size } from '@styles';

const FeatureTileStyled = styled(Grid)({
  marginBottom: Size.Md,
});

const FeatureTileImgCell = styled(Grid)({
  paddingRight: Size.Sm,
  textAlign: Align.Center,
});

interface IFeatureTile {
  content: string | JSX.Element;
  imgAlt: string;
  imgSrc: string;
  title: string | JSX.Element;
}

interface IImgStyled {
  width: string;
}

const ImgStyled = styled('img')(({ width }: IImgStyled) => ({
  width,
}));

export const FeatureTile = ({
  content: subtitle,
  imgAlt,
  imgSrc,
  title,
}: IFeatureTile) => (
  <FeatureTileStyled container={true}>
    <FeatureTileImgCell item={true} xs={3}>
      <ImgStyled alt={imgAlt} src={imgSrc} width="58px" />
    </FeatureTileImgCell>
    <Grid item={true} xs={9}>
      {/* We need the H3 theme styling, but there is no H2 on the page, so updating to render H2 */}
      <H3 component="h2">{title}</H3>
      <Body lineHeight={Size.Lg}>{subtitle}</Body>
    </Grid>
  </FeatureTileStyled>
);
