import { Typography } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { MuiColour } from '@styles';
import { BaseButton } from '../BaseButton';

interface IActionButton extends ButtonProps {
  children: string | number | JSX.Element;
}

const ActionButtonStyled = styled(BaseButton)({
  textTransform: 'uppercase',
});

export const ActionButton = ({ children, ...props }: IActionButton) => (
  <ActionButtonStyled color={MuiColour.Positive} variant="contained" {...props}>
    <Typography variant="button">{children}</Typography>
  </ActionButtonStyled>
);
