import {
  CommonTextBlockKeyStrings,
  HeyLightTextBlockKeysStrings,
  PagolightTextBlockKeyStrings,
} from './types';

export const getPagolightTextBlockKey = (key: PagolightTextBlockKeyStrings) =>
  `pagolight.textBlock.${key}`;

export const getTextBlockKey = (key: CommonTextBlockKeyStrings) =>
  `textBlock.${key}`;

export const getHeyLightTextBlockKey = (key: HeyLightTextBlockKeysStrings) =>
  `heyLight.textBlock.${key}`;
