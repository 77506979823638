/* eslint-disable no-multi-assign */
import { Box, styled, Collapse } from '@mui/material';
import { Children } from '@appTypes';
import { Colour, Size } from '@styles';
import { panelContentPadding, panelContentPaddingMigrated } from '../constants';

interface IPanelFooterBase {
  isMigrated?: boolean;
  open?: boolean;
}

const PanelFooterBase = styled(Box)<IPanelFooterBase>(({ isMigrated }) => ({
  backgroundColor: Colour.White,
  borderTopLeftRadius: Size.Lg,
  borderTopRightRadius: Size.Lg,
  bottom: '0px',
  height: 'auto',
  left: '0px',
  overflow: 'hidden',
  padding: isMigrated
    ? `0px ${Size.Xl} ${Size.Lg}`
    : `0px ${Size.Md} ${Size.Xxs}`,
}));

const PanelFooterOpen = styled(PanelFooterBase)(
  ({ isMigrated, open, theme }) => {
    const padding = isMigrated
      ? `${Size.Lg} ${Size.Xl}`
      : `${Size.Xs} ${Size.Md} ${Size.Xxs}`;

    return {
      boxShadow: open ? theme.panel.boxShadow : 'unset',
      margin: '0px',
      position: 'absolute',
      width: '100%',
      zIndex: 1,
      padding: open ? padding : 0,
    };
  },
);

const useCalculatePadding = (isMigrated?: boolean) => {
  const { bottom, horizontal } = isMigrated
    ? panelContentPaddingMigrated
    : panelContentPadding;

  // adjust the closed margins to fit the full width of the parent panel
  const closedMarginAdjust = `0 -${horizontal} -${bottom} -${horizontal}`;
  const closedWidthAdjust = `calc(100% + ${horizontal} + ${horizontal})`;
  return { closedMarginAdjust, closedWidthAdjust };
};

const PanelFooterClosed = styled(PanelFooterBase)(({ isMigrated, theme }) => {
  const { closedMarginAdjust, closedWidthAdjust } =
    useCalculatePadding(isMigrated);

  return {
    margin: closedMarginAdjust,
    position: 'relative',
    width: closedWidthAdjust,
    boxShadow: theme.panel.content.boxShadow,
  };
});

interface IExpandableExpandablePanelFooter {
  closedContent: Children;
  isMigrated?: boolean;
  open: boolean;
  openContent: Children;
}

// Expandable panel will appear position relative when closed, so to not overlap any panel content
// When open this will change to position absolute to expand and overlay the panel content
export const ExpandablePanelFooter = ({
  closedContent,
  isMigrated,
  open,
  openContent,
}: IExpandableExpandablePanelFooter) => (
  <>
    <PanelFooterOpen isMigrated={isMigrated} open={open}>
      <Collapse
        in={open}
        sx={{ visibility: open ? 'visible' : 'hidden' }}
        timeout={open ? 'auto' : 200}
      >
        {openContent}
      </Collapse>
    </PanelFooterOpen>
    <PanelFooterClosed
      isMigrated={isMigrated}
      sx={{ visibility: open ? 'hidden' : 'visible' }}
    >
      {closedContent}
    </PanelFooterClosed>
  </>
);
