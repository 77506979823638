import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFormatters } from '@heidi-pay/heidi-common-fe/utils';
import {
  Body,
  Notes,
  PaymentPlanTable,
  ToggleButtonList,
} from '@heidi-pay/heidi-component-library/components';
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { Box, styled } from '@mui/material';
import { IHeyLightCreditFinanceTerm } from '@api/HeyLight';
import { RequirementsList } from '@components';
import { supportedCurrencyMapping } from '@utils/constants';
import { getHeyLightTextBlockKey } from '@utils/i18next/utils';
import { getHeyLightPricingOfferContent } from './utils/getHeyLightPricingOfferContent';

export interface IHeyLightCreditFinanceTermsOptions {
  hasBackgroundOverlay: boolean;
}

interface IHeyLightCreditFinanceTermsContent {
  currency: string;
  pricingOffers: IHeyLightCreditFinanceTerm[];
}

const StyledContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  minHeight: '300px',
  gap: theme.panel.space.xs,
}));

const SmallNote = styled(Notes)({
  lineHeight: '12px',
  fontSize: '10px',
  color: BaseColour.Grey1,
});

export const PricingOffersContent = ({
  currency,
  pricingOffers,
}: IHeyLightCreditFinanceTermsContent) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const [activeKey, setActiveKey] = useState<number>(0);
  const [activeOffer, setActiveOffer] = useState<IHeyLightCreditFinanceTerm>();
  const { formatAmountWithCurrency } = getFormatters(`${language}_CH`);

  const currencyMap =
    supportedCurrencyMapping[currency as keyof typeof supportedCurrencyMapping];

  useEffect(() => {
    if (pricingOffers.length) {
      setActiveKey(pricingOffers[0].term);
    }
  }, [pricingOffers]);

  useEffect(() => {
    if (activeKey) {
      setActiveOffer(pricingOffers.find(x => x.term === activeKey));
    }
  }, [activeKey, pricingOffers]);

  const transform = useCallback(
    ({
      installment_value: payMonthly,
      term: responseTerm,
    }: IHeyLightCreditFinanceTerm) =>
      t(getHeyLightTextBlockKey('paymentTermAndPrice.content'), {
        payMonthly: formatAmountWithCurrency(payMonthly, currencyMap),
        responseTerm,
      }),
    [currencyMap, formatAmountWithCurrency, t],
  );

  const options = pricingOffers.map(offer => ({
    key: offer.term,
    label: transform(offer),
  }));

  const { pricingTableRowsMigrated } = getHeyLightPricingOfferContent({
    activeOffer,
    currency: currencyMap,
    formatAmountWithCurrency,
    t,
  });

  return (
    <StyledContent>
      <ToggleButtonList
        onClick={setActiveKey}
        options={options}
        sx={{ marginTop: '20px' }}
        toggleButtonListName="pricing-offer-picker"
        value={activeKey}
      />
      <PaymentPlanTable rows={pricingTableRowsMigrated} scheduleRows={[]} />
      <RequirementsList />
      <Body>{t(getHeyLightTextBlockKey('toRequestFinancing.content'))}</Body>
      <SmallNote>
        {t(getHeyLightTextBlockKey('theApprovalOfALoan.content'))}
      </SmallNote>
      <SmallNote sx={{ pb: '10px' }}>
        {t(getHeyLightTextBlockKey('financeProvidedBy.content'))}
      </SmallNote>
    </StyledContent>
  );
};
