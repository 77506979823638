import {
  Body,
  Panel,
  PanelTitle,
} from '@heidi-pay/heidi-component-library/components';
import { ActionButton, AppCopyBlock, ActionButtonContainer } from '@components';
import { Align } from '@styles';
import { closeUpstreamModal } from '@utils';
import { getTextBlockKey } from '@utils/i18next/utils';

export const Error404 = () => (
  <Panel isDialog={true} minContentHeight="unset" onClose={closeUpstreamModal}>
    <PanelTitle
      title={<AppCopyBlock i18nKey={getTextBlockKey('error.404.title')} />}
    />
    <Body textAlign={Align.Center}>
      <AppCopyBlock i18nKey={getTextBlockKey('error.404.message')} />
    </Body>
    <ActionButtonContainer>
      <ActionButton onClick={closeUpstreamModal}>
        <AppCopyBlock i18nKey={getTextBlockKey('close.label')} />
      </ActionButton>
    </ActionButtonContainer>
  </Panel>
);
