import {
  Notes,
  Panel,
  PanelTitle,
} from '@heidi-pay/heidi-component-library/components';
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { Box, styled } from '@mui/material';

import {
  AppCopyBlock,
  FeatureTileMigrated,
  MessageCRIF,
  RequirementsList,
} from '@components';
import { closeUpstreamModal } from '@utils';
import { getHeyLightTextBlockKey } from '@utils/i18next/utils';
import { getFeatureTilesMigrated } from './getFeatureTilesMigrated';

const PromotionalMessageContentBase = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const PromotionalMessageContent = styled(PromotionalMessageContentBase)(
  ({ theme }) => ({
    gap: theme.panel.space.xs,
  }),
);

interface IHeyLightBnplChCard {
  isInterestFree: boolean;
}

export const HeyLightBnplChWithInstalments = ({
  isInterestFree,
}: IHeyLightBnplChCard) => (
  <Panel
    isDialog={true}
    maxContentHeight="100vh"
    minContentHeight="unset"
    onClose={closeUpstreamModal}
  >
    <PromotionalMessageContent>
      <PanelTitle
        title={
          <AppCopyBlock
            i18nKey={getHeyLightTextBlockKey(
              isInterestFree
                ? 'buyNowPayLaterSwissInterestFree.title'
                : 'buyNowPayLaterSwiss.title',
            )}
          />
        }
      />
      <PromotionalMessageContentBase>
        {getFeatureTilesMigrated(isInterestFree).map(props => (
          <FeatureTileMigrated
            {...props}
            isCompact={true}
            key={props.Icon.name}
          />
        ))}
      </PromotionalMessageContentBase>
      <PromotionalMessageContentBase>
        <AppCopyBlock
          i18nKey={getHeyLightTextBlockKey(
            'firstInstallmentIsPaidAtTimeOfPurchase.content',
          )}
        />
      </PromotionalMessageContentBase>
      <RequirementsList />
      <MessageCRIF />
      <Notes color={BaseColour.Grey1}>
        <AppCopyBlock
          i18nKey={getHeyLightTextBlockKey(
            'readTermsAndConditionsAndPrivacyPolicy.content',
          )}
        />
      </Notes>
    </PromotionalMessageContent>
  </Panel>
);
