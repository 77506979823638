import { FormatWithCurrency } from '@heidi-pay/heidi-common-fe/types';
import {
  IPaymentRowProps,
  RowType,
} from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { IHeyLightCreditFinanceTerm } from '@api/HeyLight';
import { Translate } from '@appTypes';
import { getHeyLightTextBlockKey } from '@utils/i18next/utils';

interface IGetPagolightProPricingOffer {
  activeOffer?: IHeyLightCreditFinanceTerm;
  currency: string;
  formatAmountWithCurrency: FormatWithCurrency;
  t: Translate;
}

const getHeyLightPricingTableRows = ({
  activeOffer,
  currency,
  formatAmountWithCurrency,
  t,
}: IGetPagolightProPricingOffer): IPaymentRowProps[] => {
  if (!activeOffer) {
    return [];
  }

  const {
    installment_value: installmentValue,
    interest_amount: interestAmount,
    interest_rate: interestRate,
    term,
    total_payment: totalPayment,
  } = activeOffer;

  return [
    {
      displayValueBold: true,
      label: t(getHeyLightTextBlockKey('installments.label'), { term }),
      value: formatAmountWithCurrency(installmentValue, currency),
    },
    {
      displayValueBold: true,
      label: t(getHeyLightTextBlockKey('creditAmount.label')),
      value: formatAmountWithCurrency(
        Number(totalPayment) - Number(interestAmount),
        currency,
      ),
    },
    {
      displayValueBold: true,
      label: t(getHeyLightTextBlockKey('creditDuration.label')),
      value: term,
    },
    { label: '-', rowType: RowType.Divider },
    {
      displayValueBold: true,
      label: t(getHeyLightTextBlockKey('effectiveAnnualInterestRate.label')),
      value: `${Number(interestRate).toFixed(2)}%`,
    },
    {
      displayValueBold: true,
      label: t(getHeyLightTextBlockKey('totalPriceIncludingInterest.label')),
      value: formatAmountWithCurrency(totalPayment, currency),
    },
  ];
};

export const getHeyLightPricingOfferContent = ({
  activeOffer,
  currency,
  formatAmountWithCurrency,
  t,
}: IGetPagolightProPricingOffer) => ({
  pricingTableRowsMigrated: getHeyLightPricingTableRows({
    activeOffer,
    currency,
    formatAmountWithCurrency,
    t,
  }),
});
