import { Box, styled } from '@mui/material';
import { Children } from '@appTypes';
import { Size } from '@styles';
import { panelContentPadding } from './constants';

const { bottom, horizontal, top } = panelContentPadding;

const PanelContentStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.panel.content.background,
  borderTopLeftRadius: Size.Lg,
  borderTopRightRadius: Size.Lg,
  marginTop: Size.Md,
  padding: `${top} ${horizontal} ${bottom}`,
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.panel.content.boxShadow,
}));

interface IModalTitle {
  children: Children;
  minHeight?: string;
}

export const PanelContent = ({ children, minHeight }: IModalTitle) => (
  <PanelContentStyled minHeight={minHeight}>{children}</PanelContentStyled>
);
