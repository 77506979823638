import {
  IPaymentRowProps,
  PaymentPlanTable,
} from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { Table, TableBody, TableContainer } from '@mui/material';
import { IPricingTableRow, PricingTableRow } from './PricingTableRow';

interface IPricingTable {
  isMigrated?: boolean;
  pricingValues: IPricingTableRow[];
  pricingValuesMigrated: IPaymentRowProps[];
}

export const PricingTable = ({
  isMigrated,
  pricingValues,
  pricingValuesMigrated,
}: IPricingTable) =>
  isMigrated ? (
    <PaymentPlanTable rows={pricingValuesMigrated} scheduleRows={[]} />
  ) : (
    <TableContainer>
      <Table size="small" sx={{ overflow: 'hidden' }}>
        <TableBody>
          {pricingValues.map((values, index) => (
            <PricingTableRow
              key={`pricing-row-${values.label ?? index}`}
              {...values}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
