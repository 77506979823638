import { useTheme } from '@mui/material';

export const PagolightLink = () => {
  const theme = useTheme();
  const { main } = theme.palette.primary;

  return (
    <a
      href="https://www.pagolight.it/"
      rel="noreferrer"
      style={{ color: main }}
      target="_blank"
    >
      www.pagolight.it
    </a>
  );
};
