export enum ApplicationRoutes {
  // HeyLight
  HeyLightBnplCh = 'heylight-bnpl-ch',
  HeyLightBnplChCard = 'heylight-bnpl-ch-card',
  HeyLightBnplChWithCharge = 'heylight-bnpl-ch-with-charge',
  HeyLightBnplIt = 'heylight-bnpl-it',
  HeyLightFinancingCh = 'heylight-financing-ch',
  HeyLightFinancingIt = 'heylight-financing-it',
  HeyLightSplashScreen = 'heylight-splash-screen',
  // Legacy
  PagolightProPricingOffers = 'pagolight-pro-pricing-offers',
  PagolightPromotion = 'pagolight-promotion',
}

export enum Language {
  De = 'de',
  En = 'en',
  Fr = 'fr',
  It = 'it',
}

export enum Originators {
  HeidiPay = 'HeidiPay',
  PagoLight = 'PagoLight',
  PagoLightPro = 'PagoLightPro',
}

export enum ThemeOverride {
  HeyLight = 'HeyLight',
  Legacy = 'Legacy',
}
