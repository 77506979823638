import { Outlet } from 'react-router-dom';
import { Box, Modal, styled } from '@mui/material';
import { useSetLanguage } from '@utils/i18next/hooks';

const ModalBody = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Layout = () => {
  useSetLanguage();

  return (
    <Modal open={true}>
      <ModalBody>
        <Outlet />
      </ModalBody>
    </Modal>
  );
};
