import { Children } from '@appTypes';
import { Panel, PanelHeader, PanelContent, PanelTitle } from '@components';

interface IPanelDialog {
  children: Children;
  minContentHeight?: string;
  onClose: () => void;
  subtitle?: string | JSX.Element;
  title: string | JSX.Element;
  width?: string;
}

export const PanelDialog = ({
  children,
  minContentHeight = '500px',
  onClose,
  subtitle,
  title,
  width = '360px',
}: IPanelDialog) => (
  <Panel width={width}>
    <PanelHeader onCloseBtnClick={onClose} />
    <PanelTitle subTitle={subtitle} title={title} />
    <PanelContent minHeight={minContentHeight}>{children}</PanelContent>
  </Panel>
);
