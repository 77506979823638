export enum MuiColour {
  Error = 'error',
  Info = 'info',
  Inherit = 'inherit',
  Negative = 'negative',
  Positive = 'positive',
  PositiveAlt = 'positiveAlt',
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  ToggleSelected = 'toggleSelected',
  ToggleUnselected = 'toggleUnselected',
  Warning = 'warning',
}

export enum Colour {
  BgLightGrey = 'rgb(236, 233, 234)',
  Black = 'rgb(0, 0, 0)',
  Black30 = 'rgba(0, 0, 0, 0.3)',
  Black50 = 'rgba(0, 0, 0, 0.5)',
  DarkGrey = 'rgb(35, 35, 35)',
  MidGrey = 'rgb(218, 218, 218)',
  TxtGrey = 'rgb(51, 51, 51)',
  Unset = 'unset',
  White = 'rgb(255, 255, 255)',
}

export enum PagolightProColour {
  DarkRed = 'rgb(138, 16, 2)',
  LogoRed = 'rgba(227, 6, 19)',
  LogoRed50 = 'rgba(227, 6, 19, 0.5)',
}

export enum Font {
  SourceSans = '"Source Sans 3"',
  TitilliumWeb = 'Titillium Web',
}

export enum Align {
  Center = 'center',
  Inherit = 'inherit',
  Justify = 'justify',
  Left = 'left',
  Right = 'right',
}

export type TextAlign = 'inherit' | 'left' | 'center' | 'right' | 'justify';

/* eslint-disable typescript-sort-keys/string-enum */
export enum Size {
  Xxs = '4px',
  Xs = '8px',
  Sm = '12px',
  Md = '16px',
  Lg = '20px',
  Xl = '24px',
  Xxl = '32px',
}
/* eslint-enable typescript-sort-keys/string-enum */
