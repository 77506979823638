import {
  IPaymentRowProps,
  RowType,
} from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { IPricingOffer } from '@api';
import { Translate } from '@appTypes';
import { AppCopyBlock } from '@components';
import {
  getHeyLightTextBlockKey,
  getPagolightTextBlockKey,
} from '@utils/i18next/utils';

interface IGetPagolightProPricingOffer {
  activeOffer?: IPricingOffer;
  isMigrated?: boolean;
  t: Translate;
}

const getPricingTableRows = ({
  activeOffer,
  t,
}: IGetPagolightProPricingOffer) => {
  if (!activeOffer) {
    return [];
  }

  const {
    apr: taeg,
    nominal_interest_rate: tan,
    pay_monthly: payMonthly,
    requested_amount: creditAmount,
    response_term: responseTerm,
    total_amount_payable: totalAmountPayable,
  } = activeOffer;

  return [
    {
      label: t(getPagolightTextBlockKey('creditAmount.label')),
      value: creditAmount,
    },
    {
      label: t(getPagolightTextBlockKey('payPerMonth.label')),
      value: payMonthly,
    },
    {
      label: t(getPagolightTextBlockKey('payLater.label')),
      value: t(getPagolightTextBlockKey('paymentTermAndPrice.content'), {
        payMonthly,
        responseTerm: responseTerm - 1,
      }),
    },
    {
      label: t(getPagolightTextBlockKey('amountDue.label')),
      value: totalAmountPayable,
      hasBorderTop: true,
    },
    {
      value: t(getPagolightTextBlockKey('tanTagSummary.label'), { taeg, tan }),
    },
  ];
};

const getHeyLightPricingTableRows = ({
  activeOffer,
  t,
}: IGetPagolightProPricingOffer): IPaymentRowProps[] => {
  if (!activeOffer) {
    return [];
  }

  const {
    apr: taeg,
    nominal_interest_rate: tan,
    pay_monthly: payMonthly,
    requested_amount: creditAmount,
    response_term: responseTerm,
    total_amount_payable: totalAmountPayable,
  } = activeOffer;

  return [
    {
      displayValueBold: true,
      label: t(getHeyLightTextBlockKey('creditAmount.label')),
      value: creditAmount,
    },
    {
      displayValueBold: true,
      label: t(getHeyLightTextBlockKey('payPerMonth.label')),
      value: payMonthly,
    },
    {
      displayValueBold: true,
      label: t(getHeyLightTextBlockKey('payLater.label')),
      value: t(getHeyLightTextBlockKey('paymentTermAndPrice.content'), {
        payMonthly,
        responseTerm: responseTerm - 1,
      }),
    },
    { label: '-', rowType: RowType.Divider },
    {
      displayValueBold: true,
      label: t(getHeyLightTextBlockKey('amountDue.label')),
      value: totalAmountPayable,
      labelVariant: 'h6',
      valueVariant: 'h6',
    },
    {
      displayValueBold: true,
      label: t(getHeyLightTextBlockKey('tan.label')),
      value: tan,
      labelVariant: 'h6',
      valueVariant: 'h6',
    },
    {
      displayValueBold: true,
      label: t(getHeyLightTextBlockKey('taeg.label')),
      value: taeg,
      labelVariant: 'h6',
      valueVariant: 'h6',
    },
  ];
};

const getPricingOfferSmallPrintContent = (
  activeOffer?: IPricingOffer,
  isMigrated?: boolean,
) => {
  if (!activeOffer) {
    return {};
  }

  const {
    annual_comunication_fees: annualCommunicationFees,
    application_fee_amount: applicationFeeAmount,
    apr: taeg,
    collection_fees: collectionFees,
    merchant_name: merchantName,
    nominal_interest_rate: tan,
    pay_monthly: payMonthly,
    requested_amount: creditAmount,
    response_term: responseTerm,
    stamp_duty_for_transparency: stampDutyForTransparency,
    tax_amount: taxAmount,
    total_amount_payable: totalAmountPayable,
  } = activeOffer;

  const getTextBlockKey = isMigrated
    ? getHeyLightTextBlockKey
    : getPagolightTextBlockKey;

  return {
    closedLinkText: (
      <AppCopyBlock
        i18nKey={getTextBlockKey('pricingConditionTerms.closed.linkText')}
      />
    ),
    closedContent: (
      <AppCopyBlock
        i18nKey={getTextBlockKey('pricingConditionTerms.closed.content')}
        values={{
          merchantName,
          taeg,
          tan,
        }}
      />
    ),
    openTitle: (
      <AppCopyBlock
        i18nKey={getTextBlockKey('pricingConditionTerms.open.title')}
      />
    ),
    openContent: (
      <AppCopyBlock
        i18nKey={getTextBlockKey('pricingConditionTerms.open.content')}
        values={{
          annualCommunicationFees,
          applicationFeeAmount,
          collectionFees,
          creditAmount,
          merchantName,
          payMonthly,
          responseTerm,
          stampDutyForTransparency,
          taeg,
          tan,
          taxAmount,
          totalAmountPayable,
        }}
      />
    ),
  };
};

export const getPagolightProPricingOfferContent = ({
  activeOffer,
  isMigrated,
  t,
}: IGetPagolightProPricingOffer) => ({
  pricingTableRows: getPricingTableRows({ activeOffer, t }),
  pricingTableRowsMigrated: getHeyLightPricingTableRows({ activeOffer, t }),
  pricingOfferSmallPrint: getPricingOfferSmallPrintContent(
    activeOffer,
    isMigrated,
  ),
});
