import { Trans } from 'react-i18next';
import { TranslateInterpolation } from '@appTypes';

interface ICopyBlock {
  extraComponents?: Record<string, JSX.Element>;
  i18nKey: string;
  values?: TranslateInterpolation;
}

export const CopyBlock = ({
  extraComponents = {},
  i18nKey,
  values,
}: ICopyBlock) => (
  <Trans
    components={{ br: <br />, b: <b />, ...extraComponents }}
    i18nKey={i18nKey}
    values={values}
  />
);
