/* eslint-disable max-len */
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { ThemeOptions } from '@mui/material';
import { Colour, Font, PagolightProColour, Size } from './enums';

export const compassTheme: ThemeOptions = {
  palette: {
    primary: {
      main: PagolightProColour.LogoRed,
      light: PagolightProColour.LogoRed50,
    },
    positive: {
      main: Colour.TxtGrey,
      light: Colour.TxtGrey,
      contrastText: BaseColour.White,
      dark: Colour.DarkGrey,
    },
    toggleSelected: {
      main: PagolightProColour.LogoRed,
      light: PagolightProColour.LogoRed50,
      contrastText: BaseColour.White,
      dark: PagolightProColour.LogoRed,
    },
    toggleUnselected: {
      main: Colour.White,
      contrastText: Colour.TxtGrey,
      light: Colour.Black30,
      dark: BaseColour.Grey4,
    },
  },
  button: {
    borderRadius: Size.Xxl,
    padding: `${Size.Sm} ${Size.Lg}`,
    toggleButton: {
      padding: `${Size.Sm} ${Size.Lg}`,
    },
  },
  panel: {
    background: Colour.MidGrey,
    boxShadow: `0 0 ${Size.Xs} ${Size.Xxs} ${Colour.BgLightGrey}`,
    header: {
      padding: `${Size.Xs} ${Size.Xs} ${Size.Xs} ${Size.Md}`,
      closeButtonColour: BaseColour.White,
    },
    content: {
      background: Colour.BgLightGrey,
      padding: `${Size.Xl} ${Size.Md} ${Size.Xs} ${Size.Md}`,
    },
    space: {
      paragraph: Size.Xxl,
      xxxs: Size.Xxs,
      xxs: Size.Xs,
      xs: Size.Sm,
      xl: Size.Xl,
    },
  },
  typography: {
    fontFamily: `${Font.TitilliumWeb}, Arial`,
    body1: {
      color: Colour.TxtGrey,
      fontSize: Size.Md,
      lineHeight: Size.Xl,
    },
    body2: {
      color: Colour.TxtGrey,
      fontSize: Size.Md,
      lineHeight: Size.Lg,
    },
    button: {
      fontSize: Size.Xl,
      fontWeight: 'bold',
      lineHeight: Size.Xl,
    },
    h2: {
      fontWeight: 'bold',
      lineHeight: Size.Xxl,
    },
    h3: {
      fontSize: Size.Xl,
      lineHeight: Size.Xl,
      color: Colour.TxtGrey,
      fontWeight: 'bold',
    },
    subtitle1: {
      color: Colour.TxtGrey,
      fontSize: '18px',
      lineHeight: Size.Lg,
    },
    caption: {
      fontSize: Size.Sm,
      lineHeight: Size.Sm,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          '&.MuiButton-containedWhite:hover': {
            backgroundColor: Colour.MidGrey,
          },
          '&.MuiButton-containedToggleSelected': {
            boxShadow: `0 2px ${Size.Sm} ${PagolightProColour.LogoRed50}`,
          },
          '&.MuiButton-containedToggleUnselected': {
            boxShadow: `0 2px ${Size.Sm} ${Colour.Black30}`,
          },
          '&.MuiButton-containedToggleSelected span:first-of-type, &.MuiButton-containedToggleUnselected span:first-of-type':
            { fontSize: '24px' },
        },
      },
    },
  },
};
