import {
  Bag,
  Card,
  MousePointer,
  ThumbsUpCalculator,
} from '@heidi-pay/heidi-component-library/components';
import { AppCopyBlock } from '@components';
import { getHeyLightTextBlockKey } from '@utils/i18next/utils';

export const getFeatureTilesMigrated = (isInterestFree: boolean) => [
  {
    Icon: Bag,
    title: (
      <AppCopyBlock
        i18nKey={getHeyLightTextBlockKey('addYourFavouriteItems.title')}
      />
    ),
  },
  {
    Icon: MousePointer,
    title: (
      <AppCopyBlock
        i18nKey={getHeyLightTextBlockKey('selectHeyLightAtCheckout.title')}
      />
    ),
  },
  {
    Icon: Card,
    title: (
      <AppCopyBlock
        i18nKey={getHeyLightTextBlockKey('enterYourCardDetails.title')}
      />
    ),
  },
  {
    Icon: ThumbsUpCalculator,
    title: (
      <AppCopyBlock
        i18nKey={getHeyLightTextBlockKey(
          isInterestFree
            ? 'payInInterestFreeInstalments.title'
            : 'payInMonthlyInstalments.title',
        )}
      />
    ),
  },
];
