import { Box, styled, BoxProps, useTheme } from '@mui/material';
import { Logo, CloseButton } from '@components';

const PanelHeaderStyled = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.panel.header.padding,
}));

interface IPanelHeader extends BoxProps {
  onCloseBtnClick: () => void;
}

export const PanelHeader = ({ onCloseBtnClick }: IPanelHeader) => {
  const theme = useTheme();
  return (
    <PanelHeaderStyled>
      <Logo />
      <CloseButton
        colour={theme.panel.header.closeButtonColour}
        onClick={onCloseBtnClick}
      />
    </PanelHeaderStyled>
  );
};
