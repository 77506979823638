import { Size } from '@styles';

export const panelContentPadding = {
  horizontal: Size.Md,
  top: Size.Xl,
  bottom: Size.Xs,
};

export const panelContentPaddingMigrated = {
  horizontal: Size.Xl,
  top: Size.Lg,
  bottom: Size.Lg,
};
