import { Link, Notes } from '@heidi-pay/heidi-component-library/components';
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { styled } from '@mui/material';
import { InfoIcon, LinkButton } from '@components';

interface IExpandableTermsPanelClosedContent {
  closedContent: string | JSX.Element;
  closedLinkText: string | JSX.Element;
  isMigrated?: boolean;
  toggleOpen: () => void;
}

interface IStyledMigrated {
  isMigrated?: boolean;
}

const ClosedNotes = styled(Notes)<IStyledMigrated>(({ isMigrated }) => ({
  fontSize: isMigrated ? '10px' : '11px',
  lineHeight: '12px',
  color: isMigrated ? BaseColour.Grey1 : 'inherit',
}));

export const ExpandableTermsPanelClosedContent = ({
  closedContent,
  closedLinkText,
  isMigrated,
  toggleOpen,
}: IExpandableTermsPanelClosedContent) => (
  <>
    {isMigrated ? (
      <Link
        fontSize="10px"
        lineHeight="12px"
        onClick={toggleOpen}
        variant="caption"
        withIcon={true}
      >
        {closedLinkText}
      </Link>
    ) : (
      <LinkButton onClick={toggleOpen}>
        <Notes fontWeight="bold" mr="2px">
          {closedLinkText}
        </Notes>
        <InfoIcon />
      </LinkButton>
    )}
    <ClosedNotes component="div" isMigrated={isMigrated}>
      {closedContent}
    </ClosedNotes>
  </>
);
